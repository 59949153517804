import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static outlets = ["jp-self-service--line-item"];
  static targets = [
    "addNewItem",
    "consumableItemsTotalExclTax",
    "consumableItemsTax",
    "consumableItemsTotal",
    "eightPercentTotalExclTax",
    "eightPercentTax",
    "eightPercentTotal",
    "generalItemsTotalExclTax",
    "generalItemsTax",
    "generalItemsTotal",
    "orderTotalExclTax",
    "orderTax",
    "orderTotal",
    "required",
    "tenPercentTotalExclTax",
    "tenPercentTax",
    "tenPercentTotal",
    "totalPrice",
    "totalTax",
    "itemsCounter",
    "applyCategoryToAllModal",
    "categoryName",
  ];

  connect() {
    if (!this.hasJpSelfServiceLineItemOutlet && this.hasAddNewItemTarget) {
      this.addNewItemTarget.click();
    }

    this.refresh();
  }

  jpSelfServiceLineItemOutletConnected(outlet, _) {
    outlet.triggersRefreshTargets.forEach((target) => {
      target.addEventListener("change", this.refresh.bind(this));
      target.addEventListener("input", this.refresh.bind(this));
    });
  }

  jpSelfServiceLineItemOutletDisconnected(outlet, _) {
    this.refresh();

    outlet.triggersRefreshTargets.forEach((target) => {
      target.removeEventListener("change", this.refresh.bind(this));
      target.removeEventListener("input", this.refresh.bind(this));
    });
  }

  refresh() {
    this.orderTotalTarget.innerHTML = this.calculateOrderTotal();
    this.orderTaxTarget.innerHTML = this.calculateOrderTax();
    this.tenPercentTotalTarget.innerHTML = this.calculateTenPercentTotal();
    this.tenPercentTaxTarget.innerHTML = this.calculateTenPercentTax();
    this.eightPercentTotalTarget.innerHTML = this.calculateEightPercentTotal();
    this.eightPercentTaxTarget.innerHTML = this.calculateEightPercentTax();
    this.generalItemsTotalTarget.innerHTML = this.calculateGeneralItemsTotal();
    this.generalItemsTaxTarget.innerHTML = this.calculateGeneralItemsTax();
    this.consumableItemsTotalTarget.innerHTML =
      this.calculateConsumableItemsTotal();
    this.consumableItemsTaxTarget.innerHTML =
      this.calculateConsumableItemsTax();

    this.orderTotalExclTaxTarget.innerHTML = this.calculateOrderTotalExclTax();
    this.tenPercentTotalExclTaxTarget.innerHTML =
      this.calculateTenPercentTotalExclTax();
    this.eightPercentTotalExclTaxTarget.innerHTML =
      this.calculateEightPercentTotalExclTax();
    this.generalItemsTotalExclTaxTarget.innerHTML =
      this.calculateGeneralItemsTotalExclTax();
    this.consumableItemsTotalExclTaxTarget.innerHTML =
      this.calculateConsumableItemsTotalExclTax();

    this.itemsCounterTarget.innerHTML = this.calculateTotalNumberOfItems();
  }

  calculateOrderTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.totalPriceExclTax);
      sum += parseFloat(outlet.tax);
    });

    return this.formatAmount(sum);
  }

  calculateOrderTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.totalPriceExclTax);
    });

    return this.formatAmount(sum);
  }

  items() {
    return this.jpSelfServiceLineItemOutlets;
  }

  keptItems() {
    return this.items().filter((outlet) => !outlet.markedForDestruction());
  }

  calculateOrderTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.tax);
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      sum += parseFloat(outlet.totalPriceExclTax);
      sum += parseFloat(outlet.tax);
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isStandardTaxRate) {
        sum += parseFloat(outlet.totalPriceExclTax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateTenPercentTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isStandardTaxRate) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.totalPrice);
      }
    });

    return this.formatAmount(sum);
  }

  calculateEightPercentTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isReducedTaxRate) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateGeneralItemsTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (!outlet.isConsumable) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTotal() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTotalExclTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isConsumable) {
        sum += parseFloat(outlet.totalPriceExclTax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateConsumableItemsTax() {
    let sum = 0;

    this.keptItems().forEach((outlet) => {
      if (outlet.isConsumable) {
        sum += parseFloat(outlet.tax);
      }
    });

    return this.formatAmount(sum);
  }

  calculateTotalNumberOfItems() {
    return this.keptItems().reduce((sum, item) => sum + item.quantity, 0);
  }

  formatAmount(amount) {
    return new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
      maximumFractionDigits: 0,
    }).format(amount);
  }

  toggleReducedTaxRate() {
    this.#toggleApplyAllAction(
      "toggleReducedTaxRateIcon",
      (item) => !item.isReducedTaxRate,
    );
  }

  toggleConsumable() {
    this.#toggleApplyAllAction(
      "toggleConsumableIcon",
      (item) => !item.isConsumable,
    );
  }

  toggleTaxIncludedInTotal() {
    this.#toggleApplyAllAction(
      "toggleTaxIncludedInTotalIcon",
      (item) => !item.taxIncludedInTotal,
    );
  }

  toggleAlcohol() {
    this.#toggleApplyAllAction("toggleAlcoholIcon", (item) => !item.isAlcohol);
  }

  //
  // Apply Category to All
  //

  showApplyCategoryToAllModal(event) {
    const selector = event.target;

    if (
      selector.value !== "" && // don't apply empty category
      this.keptItems().length > 1 && // don't apply to single line item
      this.isChildOfFirstKeptLineItem(selector) // triggered by first item
    ) {
      this.categoryNameTarget.innerText = selector.value;
      this.applyCategoryToAllModal.modal("show");
    }
  }

  get applyCategoryToAllModal() {
    const modal = $(`#${this.applyCategoryToAllModalTarget.id}`);

    return modal;
  }

  triggerApplyCategoryToAll() {
    this.applyCategoryToAllModal.modal("hide");
    this.applyCategoryToAll(this.keptItems()[0].categoryTarget.value);
  }

  isChildOfFirstKeptLineItem(element) {
    return this.keptItems()[0].element.contains(element);
  }

  applyCategoryToAll(category) {
    this.keptItems().forEach((outlet) => {
      outlet.categoryTarget.value = category;
    });
  }

  #toggleApplyAllAction(targetName, filter) {
    if (this.items().some(filter)) {
      this.items()
        .filter(filter)
        .forEach((item) => {
          item.targets.find(targetName).click();
        });
    } else {
      this.items().forEach((item) => {
        item.targets.find(targetName).click();
      });
    }
  }
}
