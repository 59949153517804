import { Controller } from "@hotwired/stimulus";
import { TelephoneNumberField } from "components/telephone_number_field";
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ["form", "submit", "email", "phone"];

  connect() {
    this.phoneInput = new TelephoneNumberField(this.phoneTarget.id);

    this.emailTarget.addEventListener("input", () => {
      this.emailTarget.setCustomValidity("");
    });

    this.phoneTarget.addEventListener("input", () => {
      this.phoneTarget.setCustomValidity("");
    });
  }

  submit(event) {
    event.preventDefault();

    // Clear previous custom validity messages
    this.emailTarget.setCustomValidity("");
    this.phoneTarget.setCustomValidity("");

    // Check if both email and phone are missing
    if (!this.emailTarget.value && !this.phoneTarget.value) {
      event.stopPropagation();

      // Find the input that's currently shown
      const activeInput = [this.emailTarget, this.phoneTarget].find(
        function (input) {
          return input.closest(".tab-pane").classList.contains("active");
        },
      );

      const validationMessage = I18n.t(
        "merchant.jpstore.review.send.errors.email_or_phone_required",
        { defaultValue: "Either an email or a phone number is required." },
      );
      activeInput.setCustomValidity(validationMessage);

      // Display validation message
      activeInput.reportValidity();

      return false; // Stop submission
    }

    if (this.emailTarget.value && !this.emailTarget.checkValidity()) {
      event.stopPropagation();
      this.emailTarget.reportValidity();
      return false;
    }

    if (!this.phoneInput.isValid()) {
      event.stopPropagation();

      this.phoneInput.markAsInvalid();

      this.phoneTarget.setCustomValidity(
        I18n.t("merchant.jpstore.review.send.errors.invalid_phone_number", {
          defaultValue: "Please enter a valid phone number.",
        }),
      );
      this.phoneTarget.reportValidity();

      return false;
    }

    this.phoneInput.updateForFormSubmission();

    const form = this.formTarget;
    const formData = new FormData(form);

    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.submitTarget.value = I18n.t(
            "merchant.jpstore.review.sent.button",
          );
          this.submitTarget.classList.add("send-code-success");
        } else {
          // Handle errors
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
