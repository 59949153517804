import { Controller } from "@hotwired/stimulus";
import {
  jpCalculateTaxFromTotal,
  convertToDecimalRate,
} from "../../shared/tax_calculator";
import I18n from "i18n-js";

// This controller is used for the JP line items editing tool.
export default class extends Controller {
  static targets = [
    "destroyDiscount",
    "discountedTotalPricePreview",
    "discountedTaxTotalPreview",
    "discountValue",
    "discountType",
    "totalPrice",
    "taxRatePercentage",
    "taxIncludedInTotal",
    "tax",
  ];

  connect() {
    this.updateDiscountedPreview();
  }

  selectedDiscountType() {
    return this.discountTypeTargets.find((e) => e.checked).value;
  }

  calculatePercentDiscount(discountValue, totalPrice) {
    const discountRate = convertToDecimalRate(discountValue);
    return totalPrice - totalPrice * discountRate;
  }

  calculateMoneyDiscount(discountValue, totalPrice) {
    return totalPrice - Number(discountValue);
  }

  discountedTotalPrice() {
    switch (this.selectedDiscountType()) {
      case "Discounts::Percentage":
        return this.calculatePercentDiscount(
          this.discountValueTarget.value,
          this.totalPriceTarget.value,
        );
      case "Discounts::MoneyAmount":
        return this.calculateMoneyDiscount(
          this.discountValueTarget.value,
          this.totalPriceTarget.value,
        );
      default:
        return "";
    }
  }

  discountedTaxTotal() {
    return jpCalculateTaxFromTotal(
      this.discountedTotalPrice(),
      convertToDecimalRate(this.taxRatePercentageTarget.value),
      this.taxIncludedInTotalTarget.checked,
    );
  }

  updateDiscountedPreview() {
    if (this.discountValueTarget.value > 0) {
      this.discountedTotalPricePreviewTarget.innerText = `${I18n.t("merchant.jpstore.line_items.discounted_total_price_label")}: ${this.discountedTotalPrice()}`;
      this.discountedTaxTotalPreviewTarget.innerText = `${I18n.t("merchant.jpstore.line_items.discounted_tax_total_label")}: ${this.discountedTaxTotal()}`;
    } else {
      this.discountedTotalPricePreviewTarget.innerText = "";
      this.discountedTaxTotalPreviewTarget.innerText = "";
    }
  }

  markDiscountForDestructionIfValueIsZero() {
    if (this.hasDestroyDiscountTarget && this.discountValueTarget.value == 0) {
      this.destroyDiscountTarget.value = true;
    }
  }
}
