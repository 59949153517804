import { Html5Qrcode } from "html5-qrcode";

import Fullscreen from "./fullscreen_controller";

export default class extends Fullscreen {
  static targets = ["container", "scanner"];

  connect() {
    super.connect();

    this.html5QrCode = new Html5Qrcode(this.scannerTarget.id);

    // Set up a listener to know when the user leaves fullscreen mode (e.g. by
    // hitting the escape key) so we can stop the scanner.
    this.containerTarget.addEventListener(
      "fullscreenchange",
      function () {
        if (!this.inFullscreenMode()) {
          this.stop();
        }
      }.bind(this),
    );
  }

  async launch() {
    // On an iPad camera permission prompt by the browser is behind
    // the fullscreen button in an alert. So we need to request permission
    // before we can start the scanner because the scanner itself does
    // not provide a way to run a callback right after the camera permission
    // is granted.
    await this.#requestCameraPermission();

    this.toggleFullscreen(
      this.containerTarget,
      this.start.bind(this),
      this.stop.bind(this),
    );
  }

  start() {
    this.containerTarget.classList.remove("d-none");

    this.html5QrCode.start(
      { facingMode: "environment" },
      { fps: 10, qrbox: { width: 350, height: 350 } },
      this.onSuccess.bind(this),
    );
  }

  stop() {
    this.containerTarget.classList.add("d-none");
    this.html5QrCode.stop();
  }

  cancel() {
    this.toggleFullscreen(this.containerTarget);
  }

  onSuccess(decodedText, decodedResult) {
    console.log(decodedText);
    this.html5QrCode.stop();

    this.dispatch("success", {
      detail: { decodedText: decodedText, decodedResult: decodedResult },
    });
  }

  async #requestCameraPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      console.log("Camera permission granted");
      // Immediately stop the stream since we don't need it
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error requesting camera permission:", error);
    }
  }
}
