import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleFullscreen(element, into = this.#noop, out = this.#noop) {
    if (!this.inFullscreenMode()) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // Firefox, see https://caniuse.com/mdn-api_element_requestfullscreen
        element.mozRequestFullScreen();
      }
      into.call();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      out.call();
    }
  }

  inFullscreenMode() {
    return document.fullscreenElement !== null;
  }

  #noop() {}
}
