import { Controller } from "@hotwired/stimulus";
import { pieApiFetch } from "shared/fetch_decorators";

export default class extends Controller {
  static targets = ["documentTypeSelector"];
  static values = { orderId: String, newProof: Boolean, documentType: String };

  selectVJW() {
    this.documentTypeSelectorTarget.value = "7";
    // Set the value and emit the change for other
    // controllers
    this.documentTypeSelectorTarget.dispatchEvent(new Event("change"));
  }

  async submitVjwQrCode(event) {
    const decodedText = event.detail.decodedText;
    const options = {
      method: "POST",
      body: {
        visit_japan_web_qr_code_str: decodedText,
      },
    };

    await pieApiFetch(
      `/api/orders/${this.orderIdValue}/visit_japan_web_qr_code`,
      options,
      this.scope,
    );

    window.location.reload();
  }
}
