import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "documentTypeSelector",
    "passportScanner",
    "vjwScanner",
    "documentImage",
    "dateOfExpiration",
    "passportAuthority",
  ];

  /*
   * Check references in JpNta::Trip and proofs/document_type.rb
   */

  connect() {
    this.documentTypes = {
      PASSPORT: "1",
      LANDING_PERMIT_WITH_PASSPORT: "2",
      VISIT_JAPAN_WEB_QR_CODE: "7",
    };
    this.changeInputsByDocumentType();
  }

  passportInputs() {
    let passportInputs = [
      this.dateOfExpirationTarget,
      this.passportAuthorityTarget,
    ];

    if (this.hasPassportScannerTarget) {
      passportInputs.push(this.passportScannerTarget);
    }

    if (this.hasDocumentImageTarget) {
      passportInputs.push(this.documentImageTarget);
    }

    return passportInputs;
  }

  vjwQrCodeInputs() {
    const vjwInputs = [];
    if (this.hasVjwScannerTarget) {
      vjwInputs.push(this.vjwScannerTarget);
    }
    return vjwInputs;
  }

  onlyImageInput() {
    const imageInputs = [];
    if (this.hasDocumentImageTarget) {
      imageInputs.push(this.documentImageTarget);
    }
    return imageInputs;
  }

  allInputs() {
    let allInputs = [this.dateOfExpirationTarget, this.passportAuthorityTarget];

    if (this.hasPassportScannerTarget) {
      allInputs.push(this.passportScannerTarget);
    }

    if (this.hasDocumentImageTarget) {
      allInputs.push(this.documentImageTarget);
    }

    if (this.hasVjwScannerTarget) {
      allInputs.push(this.vjwScannerTarget);
    }

    return allInputs;
  }

  changeInputsByDocumentType() {
    switch (this.documentTypeSelectorTarget.value) {
      case this.documentTypes.PASSPORT:
        this.showOnlyInput(this.passportInputs());

        break;
      case this.documentTypes.LANDING_PERMIT_WITH_PASSPORT:
        this.showOnlyInput(this.passportInputs());

        break;
      case this.documentTypes.VISIT_JAPAN_WEB_QR_CODE:
        this.dispatch("renderVjwScanner");
        this.showOnlyInput(this.vjwQrCodeInputs());
        break;

      default:
        this.showOnlyInput(this.onlyImageInput());
    }
  }

  showOnlyInput(inputs) {
    this.allInputs().forEach((input) => {
      inputs.includes(input) ? this.showInput(input) : this.hideInput(input);
    });
  }

  hideInput(target) {
    target.style.display = "none";
  }

  showInput(target) {
    target.style.display = "block";
  }
}
